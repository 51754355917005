import React from 'react';
import PropTypes from 'prop-types';

import withAppContext from '../withAppContext';
import LaRubriqueTemplate1TopBlock from '../components/LaRubrique/LaRubriqueTemplate1TopBlock';
import LaRubriqueTemplate1BottomBlock from '../components/LaRubrique/LaRubriqueTemplate1BottomBlock';

const LaRubriquePostTemplate1 = ({ post, context: { isMobile } }) => (
  <div>
    <LaRubriqueTemplate1TopBlock
      keywords={post.keywords}
      image={isMobile ?
        post.topImageMobile && post.topImageMobile.publicURL :
        post.topImage && post.topImage.publicURL
      }
      imageAlt={post.topImageAlt}
      imageText={post.topImageText}
      imageText2={post.topImageText2}
      style={isMobile ?
        post.topImageTextMobileStyle :
        post.topImageTextStyle
      }
    />
    <LaRubriqueTemplate1BottomBlock
      text1={post.row1Text}
      image1={post.row1Image && post.row1Image.publicURL}
      image1Alt={post.row1ImageAlt}
      text2={post.row2Text}
      image2={isMobile ?
        post.row2ImageMobile && post.row2ImageMobile.publicURL :
        post.row2Image && post.row2Image.publicURL
      }
      image2Alt={post.row2ImageAlt}
      image2TopMargin={post.row2ImageTopMargin}
      image2LeftMargin={post.row2ImageLeftMargin}
      p2LeftMargin={post.p2LeftMargin}
      image2Text={post.row2ImageText}
      image2TextStyle={isMobile ?
        post.row2ImageTextMobileStyle :
        post.row2ImageTextStyle
      }
    />
  </div>
);

LaRubriquePostTemplate1.propTypes = {
  post: PropTypes.shape({
    keywords: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    topImage: PropTypes.shape({
      publicURL: PropTypes.string.isRequired,
    }).isRequired,
    topImageMobile: PropTypes.shape({
      publicURL: PropTypes.string.isRequired,
    }).isRequired,
    topImageAlt: PropTypes.string.isRequired,
    topImageText: PropTypes.string.isRequired,
    topImageText2: PropTypes.string,
    topImageTextStyle: PropTypes.shape({
      fontSize: PropTypes.string.isRequired,
      margin: PropTypes.string.isRequired,
      fontSize2: PropTypes.string,
      margin2: PropTypes.string,
    }).isRequired,
    topImageTextMobileStyle: PropTypes.shape({
      fontSize: PropTypes.string.isRequired,
      margin: PropTypes.string.isRequired,
      fontSize2: PropTypes.string,
      margin2: PropTypes.string,
    }).isRequired,
    row1Text: PropTypes.string.isRequired,
    row1Image: PropTypes.shape({
      publicURL: PropTypes.string.isRequired,
    }).isRequired,
    row1ImageAlt: PropTypes.string.isRequired,
    row2Text: PropTypes.string.isRequired,
    row2Image: PropTypes.shape({
      publicURL: PropTypes.string.isRequired,
    }).isRequired,
    row2ImageMobile: PropTypes.shape({
      publicURL: PropTypes.string.isRequired,
    }).isRequired,
    row2ImageAlt: PropTypes.string.isRequired,
    row2ImageTopMargin: PropTypes.number.isRequired,
    row2ImageLeftMargin: PropTypes.string.isRequired,
    p2LeftMargin: PropTypes.string.isRequired,
    row2ImageText: PropTypes.string.isRequired,
    row2ImageTextStyle: PropTypes.shape({
      fontSize: PropTypes.string.isRequired,
      textAlign: PropTypes.string,
      margin: PropTypes.string.isRequired,
    }).isRequired,
    row2ImageTextMobileStyle: PropTypes.shape({
      fontSize: PropTypes.string.isRequired,
      textAlign: PropTypes.string,
      margin: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
};

export default withAppContext(LaRubriquePostTemplate1);
