import React from 'react';
import PropTypes from 'prop-types';

import withAppContext from '../withAppContext';
import LaRubriqueTemplate2TopBlock from '../components/LaRubrique/LaRubriqueTemplate2TopBlock';
import LaRubriqueTemplate2MiddleBlock from '../components/LaRubrique/LaRubriqueTemplate2MiddleBlock';
import LaRubriqueTemplate2BottomBlock from '../components/LaRubrique/LaRubriqueTemplate2BottomBlock';

const LaRubriquePostTemplate2 = ({ post, context: { isMobile } }) => (
  <div>
    <LaRubriqueTemplate2TopBlock
      keywords={post.topBlock.keywords}
      keywordsContainerMargin={isMobile ?
        post.topBlock.keywordsContainerMarginMobile :
        post.topBlock.keywordsContainerMargin
      }
      image={post.topBlock.topImage.src && post.topBlock.topImage.src.publicURL}
      imageAlt={post.topBlock.topImage.alt}
      imageText={post.topBlock.topImageText}
      lineHMargin={post.topBlock.lineHMargin}
      imgTextStyle={isMobile ?
        post.topBlock.topImageTextMobileStyle :
        post.topBlock.topImageTextStyle
      }
      row1Text={post.topBlock.row1Text}
      row1TextMargin={isMobile ? post.topBlock.row1TextMarginMobile : post.topBlock.row1TextMargin}
      row1Image={post.topBlock.row1Image.src && post.topBlock.row1Image.src.publicURL}
      row1ImageAlt={post.topBlock.row1Image.alt}
    />
    <LaRubriqueTemplate2MiddleBlock rows={post.middleBlock.rows} />
    <LaRubriqueTemplate2BottomBlock
      row4Image={post.bottomBlock.row4Image.src && post.bottomBlock.row4Image.src.publicURL}
      row4ImageAlt={post.bottomBlock.row4Image.alt}
      row4Text={post.bottomBlock.row4Text}
      row4TextMargin={post.bottomBlock.row4TextMargin}
    />
  </div>
);


LaRubriquePostTemplate2.propTypes = {
  post: PropTypes.shape({
    topBlock: PropTypes.shape({
      keywords: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
      keywordsContainerMargin: PropTypes.string.isRequired,
      keywordsContainerMarginMobile: PropTypes.string.isRequired,
      topImage: PropTypes.shape({
        src: PropTypes.shape({
          publicURL: PropTypes.string.isRequired,
        }).isRequired,
        alt: PropTypes.string.isRequired,
      }).isRequired,
      topImageText: PropTypes.string.isRequired,
      topImageTextStyle: PropTypes.shape({
        fontSize: PropTypes.string.isRequired,
        margin: PropTypes.string.isRequired,
      }).isRequired,
      topImageTextMobileStyle: PropTypes.shape({
        fontSize: PropTypes.string.isRequired,
        margin: PropTypes.string.isRequired,
      }).isRequired,
      lineHMargin: PropTypes.string.isRequired,
      row1Text: PropTypes.string.isRequired,
      row1TextMargin: PropTypes.string.isRequired,
      row1TextMarginMobile: PropTypes.string.isRequired,
      row1Image: PropTypes.shape({
        src: PropTypes.shape({
          publicURL: PropTypes.string.isRequired,
        }).isRequired,
        alt: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    middleBlock: PropTypes.shape({
      rows: PropTypes.arrayOf(PropTypes.shape({
        image: PropTypes.shape({
          src: PropTypes.shape({
            publicURL: PropTypes.string.isRequired,
          }).isRequired,
          alt: PropTypes.string.isRequired,
        }).isRequired,
        text1: PropTypes.string.isRequired,
        text2: PropTypes.string.isRequired,
      })).isRequired,
    }).isRequired,
    bottomBlock: PropTypes.shape({
      row4Image: PropTypes.shape({
        src: PropTypes.shape({
          publicURL: PropTypes.string.isRequired,
        }).isRequired,
        alt: PropTypes.string.isRequired,
      }).isRequired,
      row4Text: PropTypes.string.isRequired,
      row4TextMargin: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
};

export default withAppContext(LaRubriquePostTemplate2);
